<template>
  <v-container>
    <div class="back-plot">
      <!-- BOC:[error] -->
      <ApiErrorDialog v-if="api.isError" :api="api" />
      <!-- EOC -->

      <v-card class="transparent" elevation="0">
        <v-card-title class="grey lighten-5 rounded-lg py-2">
          <v-row>
            <v-col cols="9" class="d-flex rounded align-center">
              <AbstractAvatar :avatar="auth.Player.avatar" :user="true" />
              <div class="ps-2 pt-2">{{ auth.User.name }}</div>
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-end">
              <CloseBtn size="40" :callback="exit" />
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>

      <v-form @submit.prevent="next">
        <div class="code-input">
          <v-text-field
            focusable
            autofocus
            maxlength="4"
            ref="inputField"
            v-model="code"
            label="Enter your pin"
          ></v-text-field>
        </div>
        <v-card
          class="mb-3 px-3 py-5"
          :class="
            settings.graphics && settings.graphics.mode == 'low'
              ? 'low_plot'
              : 'plot'
          "
        >
          <v-card-text>
            <div class="pt-1">
              <p class="text-h5 mt-3">
                {{ $t("view.PageEntranceStudentPin.title") }}
              </p>
            </div>
            <div
              v-click-outside="onClickOutside"
              class="show-code d-flex align-center text-center justify-space-around"
              ref="target-element"
            >
              <div
                :style="
                  (!code || code.length == 0) && active ? borderColor : null
                "
                @click="show"
                class="code text-h5"
              >
                <span v-if="code">{{ code.charAt(0) }}</span>
              </div>
              <div
                :style="code && code.length == 1 && active ? borderColor : null"
                @click="show"
                class="code text-h5"
              >
                <span v-if="code">{{ code.charAt(1) }}</span>
              </div>
              <div
                :style="code && code.length == 2 && active ? borderColor : null"
                @click="show"
                class="code text-h5"
              >
                <span v-if="code">{{ code.charAt(2) }}</span>
              </div>
              <div
                :style="code && code.length >= 3 && active ? borderColor : null"
                @click="show"
                class="code text-h5"
              >
                <span v-if="code">{{ code.charAt(3) }}</span>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="green lighten-1"
              large
              @click="next"
              :loading="api.isLoading"
              block
              rounded
              class="text-h6"
              style="text-transform: capitalize"
            >
              {{ $t("action.next") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
let click_sfx = new Audio(require("@/assets/sounds/sfx_click_5.wav"));
import AvatarSkeleton from "@/components/skeleton/Avatar";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),

    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
      settings: (state) => state.settings.data,
    }),
    borderColor() {
      return " border: solid #66BB6A";
    },
  },
  props: [
    //
  ],
  watch: {
    code: function (val) {
      if (val) {
        this.code = val.toUpperCase();
        this.codeLength = this.code.length;
      }
    },
  },
  data: () => ({
    active: true,
    code: null,
    codeLength: 0,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
  }),
  created() {
    //BOC:[api]{
    this.api.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/player/loginByPIN";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      this.code = null;
      this.codeLength = 0;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      var authData = this.$_.cloneDeep(this.auth);
      authData.Session = resp.Session;
      this.$store.commit("updateAuth", authData);

      this.$router.push({ name: "PageExploreLanding" });
    };
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    exit() {
      this.$router.push({
        name:
          this.auth.Group.type == "family"
            ? "PageAccessFamily"
            : "PageAccessClassroom",
      });
    },
    fetch() {
      this.api.params = {
        mochiId: this.auth.Player.mochiId,
        PIN: this.code,
        version: "1.0.0",
      };
      this.$api.fetch(this.api);
    },
    show() {
      this.$refs.inputField.focus();
      this.active = true;
      setTimeout(() => {
        this.$refs["target-element"].scrollIntoView();
      }, 500);
    },
    onClickOutside() {
      this.active = false;
    },
    next() {
      if (!(this.$route.query.express && this.$route.query.express == 1)) {
        click_sfx.play();
        click_sfx.volume = this.settings.audio.sfx * this.settings.audio.master;
      }

      if (this.codeLength == 0) {
        this.api.callbackError(
          this.$t("view.PageEntranceStudentPin.stringCodeEmpty")
        );
      } else if (this.codeLength < 4) {
        this.api.callbackError(
          this.$t("view.PageEntranceStudentPin.stringCodeIncomplete")
        );
      } else {
        this.fetch();
      }
    },
    handleInput(e) {
      if (e.key === "Backspace" || e.key === "Delete") {
        this.code = this.code.substring(0, this.code.length - 1);
        return e.preventDefault();
      }
      const value = e.target.value;
      if (value) {
        return e.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
.position-relative {
  position: relative;
}
.code-input {
  opacity: 0;
  position: absolute;
}
.code {
  background-color: rgb(230, 230, 230);
  width: 100%;
  max-width: 60px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-box-shadow: inset 0 0 5px #606060;
  -webkit-box-shadow: inset 0 0 5px #606060;
  box-shadow: inset 2px 2px 5px #8d8d8d;
}
</style>